import React from "react";
import LA from "./assets/LA.png";
import { Container } from "react-bootstrap";
const NotFound = () => {
  return (
    <div>
      <Container fluid style={{ marginTop: "40vh" }}>
        <h1>404 Not Found</h1>
        <p>
          Oops! The page you are looking for cannot be found. Maybe you are
          trying a URL that do not exist.
        </p>
        <p>Our website maybe is under update. Try again later.</p>
        <img src={LA} alt="notfound" className="img-fluid w-50" />
      </Container>
    </div>
  );
};

export default NotFound;
