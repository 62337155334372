//create a basic footer component
import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faAirbnb,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import booking from "./assets/booking.png";
import bookYogaRetreat from "./assets/bookYogaRetreats.png";

function Footer() {
  return (
    <footer className="mt-5">
      <hr />
      <Container
        fluid={true}
        className="d-flex flex-column justify-content-evenly  flex-md-row "
      >
        <div>
          <h5>
            <b>Where to find us</b>
          </h5>
          <p>R. dos Lírios 5, 2640-012</p>
          <p>Ribamar (Santo Isidoro), Portugal</p>
        </div>
        <div>
          <h5>
            <b>Contact us </b>{" "}
          </h5>
          <p>
            <a href="mailto:lalitayogacamp@gmail.com">
              lalitayogacamp@gmail.com
            </a>
          </p>
          <p>
            <a href="https://api.whatsapp.com/send?phone=351915783064">
              +351 915 783 064
            </a>
          </p>
          <p>
            <a href="https://api.whatsapp.com/send?phone=351961245072">
            +351 961 245 072
            </a>
          </p>
        </div>

        <div className="partners">
          <h5>
            {" "}
            <b>Follow us</b>
          </h5>
          <div className="social-container">
            <a
              href="https://www.instagram.com/lalita_house_yoga_camp/"
              className="instagram social"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ color: "black" }}
                size="3x"
              />
            </a>

            <br />
            <br />
            <a
              href="https://www.facebook.com/lalitahouseyogacamp/"
              className="facebook social"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ color: "black" }}
                size="3x"
              />
            </a>
          </div>
        </div>

        <div className="partners d-flex flex-column justify-content-evenly align-items-center">
          <h5>
            <b>Our partners</b>
          </h5>
          <a
            href="https://www.airbnb.pt/rooms/49984741?source_impression_id=p3_1678365477_fs4V%2BDrxhx6ZPzWu"
            className="instagram social"
          >
            <FontAwesomeIcon
              icon={faAirbnb}
              size="3x"
              style={{ color: "black" }}
            />
          </a>
          <br />

          <a href="https://www.booking.com/hotel/pt/lalita-house.html?lang=en-us&soz=1&lang_changed=1">
            <img src={booking} alt="booking" style={{ width: "200px" }} />
          </a>
          <br />

          <a href="https://www.bookyogaretreats.com/lalita-house-yoga-camp">
            <img
              src={bookYogaRetreat}
              alt="booking"
              style={{ width: "200px" }}
            />
          </a>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
