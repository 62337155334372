import React from "react";
import {  Button,Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import meditation1 from "./assets/meditation1.jpg";
import meditation2 from "./assets/meditation2.jpg";
import meditation3 from "./assets/meditation3.jpg";
import meditation4 from "./assets/meditation4.jpg";

function MeditationPage() {
  return (
    <div>
      
      <MenuBar />
      <br />
      <br />
      <Container fade fluid className="p-0">
        <Carousel fade controls={false} indicators={false}>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={meditation1} alt="Meditation course" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={meditation2} alt="Meditation course"  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={meditation3} alt="Meditation course"  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={meditation4} alt="Meditation course"  />
          </Carousel.Item>
        </Carousel>
      </Container>
      <br></br>

      <Container>
        <h1>Yoga & Meditation Course</h1>
        <h5>50h certified by Yoga Alliance</h5>
        <hr></hr>
        <p style={{ textAlign: "justify" }}>
Meditation is an integral part of the practice of Yoga. Its bigger intention is the concentration, but not only. Meditate helps explore the various levels of psychic consciousness according to your spirit; it guides you in the most virtuous direction. It can be practiced daily as a technique to reconsider your daily life thoughts and create a new, powerful, positive thought pattern (Saṅkalpa). The spiritual practice of Meditation should be introduced intensively in the Yoga practice.
The course is a very complete pre-foundation which gives you the basics for further training to transmit the knowledge of Yoga to others.
        </p>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>
              <b>Available dates</b>
            </h3>
            <ul
              style={{
                textAlign: "justify",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                margin: "auto",
              }}
            >

              <li>
                <b>September 16th – 23rd FULLY BOOKED</b>
              </li>

            </ul>
          </div>
          <br></br>

          <Button
            variant="dark"
            className="mb-5"
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Check availability
            </a>
          </Button>
        
          <h3>What will you learn</h3>
          <p style={{ textAlign: "justify" }}>
            During the Yoga & Meditation Course you will learn the practical
            steps of meditation, what Meditation is, How the mind moves in
            meditation and how the body responds to it. You will come across the
            story of Meditation and various techniques that will take your life
            in a new direction. The classes will be focus on the following
            learnings:
          </p>

          <ul style={{ textAlign: "left" }}>
            <li>
              {" "}
              <b>Concrete and abstract Meditation: </b> you will concentrate on
              any picture of a concrete object, or an abstract idea of any
              quality.
            </li>
            <li>
              {" "}
              <b>Relaxing Meditation: </b> you will lie down in śavāsana,
              visualizing positive mental figures, practicing the belly
              breathing.
            </li>
            <li>
              {" "}
              <b>Guided Meditation to focus: </b> you will learn to train the
              mind to stay attentive and focused for a long period of time.
            </li>
            <li>
              {" "}
              <b>Transformation Meditation: </b> it will lead you to a total,
              inner transformation by changing the thought-pattern that
              determines your life and your journey.
            </li>
            <li>
              {" "}
              <b>Vedic Meditation: </b> you sit comfortably with eyes closed
              allowing your mind to calm down without controlling it, the
              technique feels natural and effortless.
            </li>
            <li>
              {" "}
              <b>Expansion Meditation: </b> is it a deep state of relaxation and
              energy expansion, helping you connect to a larger kind of
              awareness of universal energy.
            </li>
            <li>
              {" "}
              <b>Japa Meditation or Mantra repetition: </b> you will learn how a
              Sanskrit Mantra is a deep energy encased in a sound or words to
              keep your mind steady.
            </li>
          </ul>
        </div>
        <div>
          <h3>Certification</h3>
          <p style={{ textAlign: "justify" }}>
            Upon successful completion of the Yoga immersion Training, the
            students receive a Certificate of 50h pre-foundation Course
            accredited by the Yoga Alliance Professional.
          </p>{" "}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <br></br>

          <h3>Daily Schedule</h3>

          <ul
            style={{
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              margin: "auto",
            }}
          >
            <li>
              <b>06.00 - 06.30 am</b> Yogic Drink
            </li>
            <li>
              <b>06.30 - 07.30 am</b> Ṣaṭkarma and Prānāyāma
            </li>
            <li>
              <b>07.30 - 08.00 am</b> Meditation
            </li>
            <li>
              <b>08.00 - 08.30 am</b> Chai and Fruit
            </li>
            <li>
              <b>08.30 - 10.00 am</b> Yoga Āsana practice
            </li>
            <li>
              <b>10.15 - 11.30 am</b> Vegetarian Breakfast
            </li>
            <li>
              <b>11.50 - 12.20 pm</b> Yoganidrā
            </li>
            <li>
              <b>12.20 - 1.20 pm</b> Theory Class 1
            </li>
            <li>
              <b>1.30 - 2.30 pm</b> Lunch
            </li>
            <li>
              <b>3.30 - 4.00 pm</b> Prānāyāma
            </li>
            <li>
              <b>4.00 - 5.20 pm</b> Theory Class 2
            </li>
            <li>
              <b>5.20 - 5.40 pm</b> Herbal tea and Fruit
            </li>
            <li>
              <b>5.40 - 7.00 pm</b> Restorative Yoga practice
            </li>
            <li>
              <b>7.30 - 8.30 pm</b> Vegetarian Dinner
            </li>
            <li>
              <b>9.00 - 9.30 pm</b> Satsanga (2 x week)
            </li>
          </ul>
        </div>
        <br></br>

        <div>
          <h3>What's included</h3>
          <ul>
            <li>Textbooks and printed course materials</li>
            <li>
              <a href="/welcomekit">Welcome BAG</a>
            </li>
            <li>Neti and Jihvadhaut Lessons</li>
            <li>All Yoga practices</li>
            <li>All workshops</li>
            <li>All theory lessons</li>
            <li>Use of yoga props during the course</li>
            <li>
              <a href="/food">Vegetarian meals</a> (3 times a day)
            </li>
            <li>
              Comfortable <a href="/house">accommodation</a> in private or
              shared rooms
            </li>
          </ul>
        </div>

        <div>
          <h3>Extra Charges</h3>
          <ul>
            <li>Airplane fees</li>
            <li>Taxi pick up from Lisboa Portela Airport 45€</li>
            <li>Taxi pick up from Lisboa Center or Train Station 55€</li>
          </ul>
        </div>

        <br></br>
      </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default MeditationPage;
