import React from "react";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import { Form, Button, Col, Row, Spinner } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useState } from "react";
import emailjs from "@emailjs/browser";

export default function BookPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [roomType, setRoomType] = useState("");
  const [coursesRetreats, setCoursesRetreats] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const serviceID = "service_itt32ns";
  const templateID = "template_vsl5znc";
  const userID = "MVtv7N734mINkSOu9";

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      fromDate === "" ||
      toDate === "" ||
      roomType === "" ||
      coursesRetreats === "" ||
      numberOfPeople === "" ||
      message === ""
    ) {
      alert("Please fill in all the fields.");
      return;
    }
    setSending(true);

    const templateParams = {
      to_name: name,
      to_email: email,
      to_phone: phone,
      to_from_date: fromDate,
      to_to_date: toDate,
      to_room_type: roomType,
      to_courses_retreats: coursesRetreats,
      to_number_of_people: numberOfPeople,
      to_message: message,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        setSending(false);
        alert("Your message was sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        alert(
          "Sorry, there was a problem sending your message. Please try again later."
        );
        setSending(false);
      });
  };

  return (
    <div>
      <MenuBar />
      <br></br>

      <div className="w-75 d-flex flex-column m-auto mt-5">
        <h1 className="text-center">Booking Form</h1>
        <br></br>

        <h5>
          <b>Thanks so much for your Booking request!  You will see a confirmation alert once the message is successfully sent!</b>
        </h5>

        <h5>
          <b>
            We will reply to you as soon as we verify the availability for
            your selected dates.
          </b>
        </h5>
        <br></br>
        <Form onSubmit={sendEmail}>
          <Row className="g-2">
            <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="Name">
                <Form.Control
                  type="text"
                  placeholder="Your name"
                  onChange={(e) => setName(e.target.value)}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="Phone Number">
                <Form.Control
                  type="text"
                  placeholder="Your phone number"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Email address"
              >
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FloatingLabel>
            </Col>{" "}
          </Row>
          <Row className="mt-2">
            <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="From">
                <Form.Control
                  type="date"
                  placeholder="Check-In"
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </FloatingLabel>
            </Col>

            <Col md>
              <FloatingLabel label="To">
                <Form.Control
                  type="date"
                  placeholder="Check-Out"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md>
              <FloatingLabel label="Room Type">
                <Form.Select
                  aria-label="Select your room"
                  onChange={(e) => setRoomType(e.target.value)}
                >
                  <option >Select one option</option>

                  <option value="Suite">Suite</option>
                  <option value="Double Room">Double Room</option>
                  <option value="Bed in Shared Room">Bed in Shared Room</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md>
              <FloatingLabel label="Courses/Retreats">
                <Form.Select
                  aria-label="Select your experience"
                  onChange={(e) => setCoursesRetreats(e.target.value)}
                >
                  <option >Select one option</option>

                  <option value="None">None</option>
                  <option value="Traditional Retreat">
                    Traditional Retreat
                  </option>
                  <option value="Immersion Training">Immersion Training</option>
                  <option value="Meditation Course">Meditation Course</option>
                  <option value="Other">Other</option>
                </Form.Select>{" "}
              </FloatingLabel>
            </Col>{" "}
          </Row>
          <Row className="mt-2">
            <Col md>
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Number of People"
              >
                <Form.Select
                  aria-label="Select the number of people"
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                >
                  <option >Select one option</option>

                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="+4">+4</option>
                </Form.Select>{" "}
              </FloatingLabel>
            </Col>{" "}
          </Row>

          <Row className="mt-2">
            <Col md>
              <FloatingLabel controlId="floatingInputGrid" label="Message">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a message here"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FloatingLabel>
            </Col>{" "}
          </Row>
          <Button
            variant="dark"
            type="submit"
            className="mt-2"
            style={{ backgroundColor: "#444945" }}
            disabled={sending}
          >
            {sending ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="visually-hidden">Sending...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Form>
      </div>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}
