import React from "react";

import MenuBar from "./MenuBar";
import MainFooter from "./MainFooter";
import ContactForm from "./ContactForm";
import { Button, Container } from "react-bootstrap";
import welcomekit from "./assets/welcomekit.jpg";

function WelcomeKit() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <Container>
        <img src={welcomekit} alt="Welcome Kit" style={{width: "60%"}}/>
        <h1>A Welcome Bag for our Students </h1>
        <br />
        <p>

          All the students who take part in the Yoga Courses and Training at the
          Lalitā House Yoga Camp, receive a special Welcome Bag, included in the
          tuition fee.

        </p>
        <br />
        <p>
          The participants receive the goods bag at their arrival, these Yoga
          practice and studying materials are useful during and after their stay
          with us.
        </p>
        <p>
          They want to be a warm welcome from the House for the participants,
          and some of them will also become an important part of the daily Yoga
          routine that they learn during the Courses.
        </p>
        <p>Here what is included:</p>
        <div className="d-flex flex-column flex-lg-row justify-content-evenly align-items-center">
          <ul style={{ textAlign: "left" }}>
            <li>
              <b>An organic cotton Welcome bag</b>
            </li>
            <li>
              <b>An organic cotton t-shirt</b>
            </li>
            <li>
              <b>Neti Pot</b>
            </li>
            <li>
              <b>Copper Tongue scraper</b>
            </li>
            <li>
              <b>Manual and printed course material</b>
            </li>
            <li>
              <b>Notebook</b>
            </li>
            <li>
              <b>Pen</b>
            </li>
          </ul>
        </div>
        <p>
        We are already looking forward to receiving you and spending a beautiful, enriching week with you in the name of Yoga.
        </p>
        <br />
        <Button
          variant="dark"
          className="mb-5"
          style={{ backgroundColor: "#444945" }}
        >
          <a href="/yoga" style={{ color: "white" }}>
            YOGA COURSES
          </a>
        </Button>
      </Container>

      <ContactForm />
      <MainFooter />
    </div>
  );
}

export default WelcomeKit;
