import React from "react";
import { Container, Button } from "react-bootstrap";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import Bahia1 from "./assets/bahia2.JPG";

import AnyQuestions from "./AnyQuestions";
function LalitaBahia() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <br />
      <Container>
      <h1>Lalita House Bahia</h1>
      <img src={Bahia1} className="img-fluid " style={{ width: "55%", marginBottom: "1%"}} alt="yoga" />
      <p>
        Welcome to the Lalitā House Bahia in Praia de Ipitanga, few km away from
        Salvador Bahia, Brazil's most joyful, colourful cities.</p>
        <p> The house can
        host 6 persons and is available for rent all year round. Three bedrooms
        with sea view, two double suites & one double room with AC or fan,
        chill-out verandas, three complete bathrooms, living & dining area,
        fully equipped kitchen. Surfboard’s storage, high speed WIFI connection.
      </p>
<h5>
Practice Yoga, Surf, Relax by the Ocean with your loved ones.
      </h5>
      <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
            >
            <a href="mailto:lalitahousebahia@gmail.com" style={{ color: "white" }}>
              Bookings
            </a>
          </Button>
          <br></br>
          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
            >
            <a href="https://www.instagram.com/oceanhousebahia" style={{ color: "white" }}>
              Instagram
            </a>
          </Button>      
          <br></br>
          <br></br>
          
              <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
            >
            <a href="https://www.airbnb.com.br/rooms/850750466482687822" style={{ color: "white" }}>
              More Info
            </a>
          </Button>
     </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default LalitaBahia;
