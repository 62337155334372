import React from "react";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import { Container, Button } from "react-bootstrap";
import event1 from "./assets/event1.png";
import event2 from "./assets/event2.png";
import event3 from "./assets/event3.png";
import forestBathing from "./assets/event4.JPG";
export default function FoodPage() {
  return (
    <div>
      <MenuBar />
      <br></br>
      <br></br>
      <br></br>
      <Container>
        <div style={{ textAlign: "center" }}>
          <img src={event2} className=" w-75" alt="japamala  " />
          <h2>Japamālā Workshop and Gāyatrī Mantra Recitation</h2>

          <p style={{ textAlign: "justify" }}>
            Japamālā (Sanskrit mālā “garland” and Japa “meditative repetition of
            a mantra”) is a necklace from the Hinduism commonly used for
            counting recitations such as mantras or other sacred sound.
          </p>
          <p style={{ textAlign: "justify" }}>
            This unique workshop is created by the Lalitā House Yoga Camp, led
            by Adriano Mesquita in collaboration with the{" "}
            <a href="https://www.instagram.com/lamagayogi/">
              Yoga teacher Gaby Ramos
            </a>{" "}
            and is open to everybody who would like to experience the creation
            of his own traditional Hindu bead’s loop.
          </p>
          <p style={{ textAlign: "justify" }}>
            You will learn the history, the signification, and the correct use
            of the Japamālā, and will be guided to build its main body of 108
            beads using the authentic, traditional rudrakṣa (Sanskrit for “tear
            of Śiva”); a dried berry that falls naturally when it is ripe.
          </p>

          <p style={{ textAlign: "justify" }}>
            During the workshop the teachers will explain and execute with you
            108 times the Gāyatrī Mantra, the sacred Mantra of the illumination,
            the wisdom and the freedom.
          </p>
          <h5>Duration: 2 hours</h5>
          <h5>Date: 17th June</h5>
          <h5>Time: 4 PM - 6 PM</h5>
          <h5>Price: <s>30€</s>/25€ person</h5>

          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Book Now
            </a>
          </Button>
        </div>
        <hr></hr>
      </Container>
      <br></br>
      <Container>
        <div style={{ textAlign: "center" }}>
          <img src={forestBathing} className=" w-75" alt="japamala  " />
          <h2>The deep healing power of Forest Bathing practice </h2>

          <p style={{ textAlign: "justify" }}>
          Lalitā House Yoga Camp presents a mindful immersion in the charming Nature of Ericeira:<a href="https://pauseintheforest.com"> the Forest bathing or forest therapy.</a> During this Body, Mind, and Soul experience you are invited to tune in, reconnect with your senses and discover new or deeper ways to connect with the natural world.
          </p>
          <p style={{ textAlign: "justify" }}>
          Unlike hiking or other strenuous outdoor activities, forest bathing is a slow and gentle practice of contemplation, meditation, and unification that is facilitated by our certified forest therapy guide Simone. 
          </p>
          <p style={{ textAlign: "justify" }}>
          We invite in a shared experience which will increase your states of well-being and joy, reducing the stress and anxiety of the everyday life.

This bucolic practice enables us to feel the healing powers of nature, leaving us more relaxed, more connected, and more present. It will take place in different nature settings, not only in the forest but also at the beach or in the garden: all we need is Nature!
          </p>

          <h5>Duration: 2 hours</h5>
          <h5>Date: 26th April</h5>
          <h5>Price: 25€/person</h5>

          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Book Now
            </a>
          </Button>
        </div>
        <hr></hr>
      </Container>
      <br></br>
      <Container>
        <div style={{ textAlign: "center" }}>
          <img
            src={event1}
            alt="yoga  of meditative concert "
            className=" w-75"
          />
          <br></br>
          <br></br>
          <h2>Yoganidrā & Meditative Concert</h2>
          <p style={{ textAlign: "justify" }}>
            This special event consists of an introspective Meditation and a
            deep healing experience across the sound; it is organized by the
            Lalitā House Yoga Camp with the special participation of the
            Portuguese <a href="https://rumar.pt"> world music artist Rumar</a>.
          </p>
          <p style={{ textAlign: "justify" }}>
            Adriano starts guiding the Prāṇāyāma and the Yoganidrā, to slow down
            our brain waves and reduce the stress. This incredible feeling of
            consciousness and relaxation induces the participants to a sense of
            unity with both their outer and inner senses.
          </p>
          <p style={{ textAlign: "justify" }}>
            Meanwhile, Rumar offers a timeless journey blending the organic
            sounds of world instruments such as Tibetan Bowls, Guzheng, Sitar,
            Harmonium, Shruti Box, Tampura, Khim, Kalimba, Jew’s Mouth Harp and
            Portuguese Guitar.
          </p>

          <p style={{ textAlign: "justify" }}>
            During the Yoganidrā the sound of the many different musical
            instruments create calming and healing vibrations that allow body
            and mind to enter a meditative state.
          </p>
          <p style={{ textAlign: "justify" }}>
            This is a journey driven by sounds and suggestions which will guide
            us through the paths of our inner selves.
          </p>
          <h5>Duration: 2 hours</h5>
          <h5>Date: 20th May </h5>
          <h5>Time: 5:30 PM - 7:30 PM</h5>
          <h5>Price: <s>30€</s> 25€/person</h5>
          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Book Now
            </a>
          </Button>
        </div>
      </Container>
      <hr></hr>
      <br></br>
      <Container>
        <div style={{ textAlign: "center" }}>
          <img
            src={event3}
            alt="yoga  of ayurveda workshop"
            className=" w-75"
          />
          <br></br>
          <br></br>
          <h2>Ayurveda workshop – Theory and practice</h2>
          <p style={{ textAlign: "justify" }}>
            Lalitā House Yoga Camp hosts an interesting workshop about the basic
            principles of Ayurveda, the therapeutic branch of Yoga, in
            collaboration with Doc. Lucía Avenatti. The event is open to
            everybody who wishes to study important fundamentals to improve
            their health and well-being.{" "}
          </p>
          <p style={{ textAlign: "justify" }}>
            Yoga & Ayurveda together promote a complete balance of mind, body
            and spirit, inspiring to live aligning with the natural rhythms.
          </p>

          <p style={{ textAlign: "justify" }}>
            The workshop approaches the following topics:
          </p>

          <ul>
            <li>Introduction to Āyurveda (āyus "life" and veda "knowledge")</li>

            <li>The three Doshas (Pitta, Vata, Kapha)</li>
            <li>Find out your body type</li>
            <li>Body balance (Prakriti) and imbalance (Vikriti)</li>
            <li>Ayurvedic diet and nutrition to suit one’s Dosha type</li>
            <li>Lifestyle approach to suit one's Dosha type</li>
          </ul>

          <p style={{ textAlign: "justify" }}>
            The participants might be beginners at first contact with the topics
            or be already familiar with the basics to deepen their knowledge and
            give a competent answer to their curiosities.
          </p>
          <h5>Duration: 2 hours</h5>
          <h5>Date: 24th April</h5>
          <h5>Date: 19th July</h5>
          <h5>Time: 4 PM - 6 PM</h5>

          <h5>Price: 20€/person</h5>
          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Book Now
            </a>
          </Button>
        </div>
      </Container>

      <br></br>
      <AnyQuestions />
      <MainFooter />
    </div>
  );
}
