import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";
import LA from "./assets/LA.png";
function MenuBar() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      fixed="top"
      className="vh-5 d-block"
    >
      <Container>
        <Navbar.Brand href="/" style={{ marginTop: "0.5%" }}>
          <img
            src={LA}
            width="160"
            height="50"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav " />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="d-lg-flex justify-content-lg-end justify-content-lg-end"
        >
          <Nav>
            <Nav.Link href="/about">OUR TEAM</Nav.Link>

            <NavDropdown title="YOGA" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/yoga">All Courses</NavDropdown.Item>
              <NavDropdown.Item href="/yoga/meditationcourse">
                Meditation Course
              </NavDropdown.Item>
              <NavDropdown.Item href="/yoga/traditionalretreat">
                Traditional Yoga Retreat
              </NavDropdown.Item>
              <NavDropdown.Item href="/yoga/immersiontraining">
                Immersion Training
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/house">HOUSE</Nav.Link>
            <Nav.Link href="/book">BOOKING</Nav.Link>
            <Nav.Link href="/food">FOOD</Nav.Link>
            <Nav.Link href="/contact">CONTACT</Nav.Link>
            <Nav.Link href="/next">EVENTS</Nav.Link>
            <Nav.Link href="/housebahia">BRAZIL</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MenuBar;
