import React from "react";
import { Container } from "react-bootstrap";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import food1 from "./assets/food1.jpg";
import food2 from "./assets/food2.jpg";
import food3 from "./assets/food3.jpg";
import food4 from "./assets/food4.jpg";
import food5 from "./assets/food5.jpg";

export default function FoodPage() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <br />
      <h1> FOOD MADE WITH LOVE</h1>
      <Container>
        <h5 style={{ textAlign: "center" }} className="m-5">
          <b>
          Can you imagine eating healthy, home-made food prepared with love every day?          </b>{" "}
        </h5>
        <p style={{ textAlign: "justify" }}>
          The team of the Lalitā House Yoga Camp takes care of preparing
          balanced meals, natural and full of positive energy. We embrace the
          harmonic Sattvic Diet described in the old Yoga and Ayurveda manuals,
          composed of vegetarian and vegan nutrients. On our table you will
          always find seasonal fruits and vegetables from the local farms, same
          for different types of legumes, whole grains, local cheeses, and
          yogurt. We use fresh ingredients to create original recipes that
          combine the sattvic and modern lifestyles.{" "}
        </p>

        <img
          src={food1}
          className="img-fluid w-75 mb-3"
          alt="food iamge of lalita house"
        />
        <img
          src={food2}
          className="img-fluid w-75 mb-3"
          alt="food iamge of lalita house"
        />
        <img
          src={food3}
          className="img-fluid w-75 mb-3"
          alt="food iamge of lalita house"
        />
        <img
          src={food4}
          className="img-fluid w-75 mb-3 "
          alt="food iamge of lalita house"
        />
        <img
          src={food5}
          className="img-fluid w-75  mb-3"
          alt="food iamge of lalita house"
        />
      </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}
