import React from "react";

function MainFooter() {
  return (
    <div className="w-100 mt-5" style={{ backgroundColor: "#b0b9af" }}>
      <p className="m-0 text-dark">
        &#169; 2023 Lalita House Yoga Camp. All rights reserved.{" "}
      </p>
    </div>
  );
}

export default MainFooter;
