import React from "react";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";

import { Container, Button } from "react-bootstrap";
import easter from "./assets/easter.jpg";

export default function Easter() {
  return (
    <div>
      <MenuBar />

      <br></br>
      <br></br>

      <Container fluid>
        <div style={{ textAlign: "center" }}>
          <img src={easter} className="img-fluid w-50 " alt="easter page" />
          <h2>Special Easter Retreat</h2>
          <h5>08th - 15th April</h5>

          <p style={{ textAlign: "justify" }}>
            For the Special Easter Retreat we invite you in a peaceful
            experience in our Home filled with Ocean energies, to get closer to
            your inner self and release the tensions of the everyday life.
          </p>
          <p style={{ textAlign: "justify" }}>
            The program, created by our Team, includes Yoga practices and
            activities, healthy meals and relaxing moments. You can expect a
            week of traditional Hatha Yoga practices by Adriano and his
            enriching teachings and workshops, also an interesting introduction
            to Yin Yoga with Babette. An Ayurveda treatment is also part of the
            program with Doc. Lucía.
          </p>
          <p style={{ textAlign: "justify" }}>
            In the morning of the Easter Sunday, we will be with you for a
            sunrise Meditation on the Ocean’s cliffs. Afterwards a not
            conventional Easter brunch will be waiting for you: a fusion,
            delicious meal with multi-ethnic influences and dishes. Reproduction
            of traditional Easter dishes in a vegan version, Gluten-free
            options, traditional products, homemade vegan cakes, and sweets.
          </p>
          <p style={{ textAlign: "justify" }}>
            The retreat will host a small group to keep the cosy, familiar
            environment of our Home.
          </p>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <br></br>

            <h3>Daily Schedule</h3>

            <ul
              style={{
                textAlign: "justify",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                margin: "auto",
              }}
            >
              <li>
                <b>06.30 am</b> Herbal Tea
              </li>
              <li>
                <b>07.00 am</b> Ṣaṭkarma practice and Meditation
              </li>
              <li>
                <b>07.45 am</b> Fruits and tea
              </li>
              <li>
                <b>08.00 am</b> Yoga Āsana and Prāṇāyāma
              </li>
              <li>
                <b>10.00 am</b> Brunch
              </li>
              <li>
                <b>Beach time, Massage session, Surf</b>
              </li>
              <li>
                <b>04.00 pm</b> Yoga workshops and lectures (1 per week)
              </li>
              <li>
                <b>05.00 pm</b> Āsana and Yoganidrā
              </li>
              <li>
                <b>07.00 pm</b> Dinner
              </li>
              <li>
                <b>08.30 pm</b> Evening meditation (1 per week)
              </li>
            </ul>
          </div>
          <br></br>
          <h3>What's included</h3>

          <ul>
            <li>
              7 nights’ accommodation (shared double or private double)
              <li>1 special Easter morning Meditation by the Ocean</li>
              <li>Morning Āsana and Prāṇāyāma practice</li>
              <li>Afternoon Yoga Āsana and Yoganidrā</li>
              <li>Afternoon Yoga workshops and lectures</li>
              <li>1 special evening Meditation (Trātaka)</li>
              <li>1 Ayurveda massage included</li>
              <li>Healthy vegetarian Brunch</li>
              <li>1 special Easter Brunch</li>
              <li>Special Vegan Dinner</li>
            </li>
          </ul>
          <br></br>
          <Button
            variant="dark"
            className=""
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Book Now
            </a>
          </Button>
        </div>
      </Container>

      <br></br>
      <br></br>
      <AnyQuestions />
      <MainFooter />
    </div>
  );
}
