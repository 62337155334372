import React from "react";
import {  Button,  Container } from "react-bootstrap";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import Carousel from "react-bootstrap/Carousel";
import immersion1 from "./assets/immersion1.jpg";
import immersion2 from "./assets/immersion2.jpg";
import immersion3 from "./assets/immersion3.jpg";
import immersion4 from "./assets/immersion4.jpg";

function ImmersionPage() {
  return (
    <div>
      <MenuBar />
      <br></br>
      <br></br>
      <Container fluid fade className="p-0">
        <Carousel fade controls={false} indicators={false}>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={immersion1} alt="Immersion course" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={immersion2} alt="Immersion course" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={immersion3} alt="Immersion course" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100" src={immersion4} alt="Immersion course" />
          </Carousel.Item>
        </Carousel>
      </Container>
      <br></br>

      <Container>
        <h1>Yoga Immersion Training</h1>
        <h5>50h certified by Yoga Alliance</h5>
        <hr></hr>

        <p style={{ textAlign: "justify" }}>
          During the Yoga Immersion Training the students live, study, practice
          all together during the whole week. The participants learn the
          classical Yoga Curriculum which opens new opportunities for deep
          personal transformation. The spiritual essence of Yoga is learned
          through in-depth lessons shared from heart to heart. This helps awaken
          self-awareness and guide the student to cultivate health and vitality.
          The course is a very complete pre-foundation which gives you the
          basics for further training to transmit the knowledge of Yoga to
          others.{" "}
        </p>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>
              <b>Available dates</b>
            </h3>
            <ul
              style={{
                textAlign: "justify",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                margin: "auto",
              }}
            >
                          <li>
                <b>July 08th - 15th FULLY BOOKED</b>
              </li>

              <li>
                <b>August 12th - 19th FULLY BOOKED</b>
              </li>
            </ul>
          </div>
          <br></br>

          <Button
            variant="dark"
            className="mb-5"
            style={{ backgroundColor: "#444945" }}
          >
            <a href="/book" style={{ color: "white" }}>
              Check availability
            </a>
          </Button>

          <h3>What will you learn</h3>
          <h5>Asanās</h5>
          <p style={{ textAlign: "justify" }}>
            During the Yoga Asanās class you will practice and study Yoga
            Postures, variations, inversions, postural alignment, different
            techniques with individual corrections.
          </p>
          <h5>Prānāyāma</h5>
          <p style={{ textAlign: "justify" }}>
            Different breathing exercises such as Kapālabhāti, Bhastrikā,
            Anuloma Viloma (alternate nostril breathing). They will help to
            expand your lung’s capacity and prepare you for Meditation. You will
            also learn the use of the Bandhas.
          </p>
          <h5>Ṣaṭkarma</h5>
          <p style={{ textAlign: "justify" }}>
            Daily workshops and practice of different Yogic cleansing exercises,
            as an organic purification. With a teacher’s guidance, you will
            learn Yoga Kriya exercises.
          </p>
          <h5>Nutrition</h5>
          <p style={{ textAlign: "justify" }}>
            The food we eat and how we eat, influences our mind, senses, and
            energy. You will receive proper balanced Sattvic Food and learn the
            important reasons of a vegetarian, healthy nutrition.
          </p>
          <h5>Meditation and Mantras</h5>
          <p style={{ textAlign: "justify" }}>
            Meditation is a part of the practice of Yoga, it is a spiritual
            journey to the realization of yourself. Mantra is sound or words, it
            is an ‘instrument for the mind’ with deep meanings for the
            practitioners.
          </p>
          <h5>Anatomy and Physiology</h5>
          <p style={{ textAlign: "justify" }}>
            This study helps you to improve the understanding of your body:
            cardiovascular, respiration, digestion, skeletal, muscular, and
            endocrine systems.
          </p>
          <h5>Vedanta Yoga</h5>
          <p style={{ textAlign: "justify" }}>
            These are the main teachings of traditional Yoga philosophy; you
            will benefit from the seeds of knowledge with classical lectures and
            the writings of the yoga masters.
          </p>
        </div>
        <div>
          <h3>Certification</h3>
          <p style={{ textAlign: "justify" }}>
            Upon successful completion of the Yoga immersion Training, the
            students receive a Certificate of 50h pre-foundation Course
            accredited by the Yoga Alliance Professional.
          </p>{" "}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <br></br>

          <h3>Daily Schedule</h3>

          <ul
            style={{
              textAlign: "justify",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              margin: "auto",
            }}
          >
            <li>
              <b>06.00 - 06.30 am</b> Yogic Drink
            </li>
            <li>
              <b>06.30 - 07.30 am</b> Ṣaṭkarma and Prānāyāma
            </li>
            <li>
              <b>07.30 - 08.00 am</b> Meditation
            </li>
            <li>
              <b>08.00 - 08.30 am</b> Chai and Fruit
            </li>
            <li>
              <b>08.30 - 10.00 am</b> Yoga Āsana practice
            </li>
            <li>
              <b>10.15 - 11.30 am</b> Vegetarian Breakfast
            </li>
            <li>
              <b>11.50 - 12.20 pm</b> Yoganidrā
            </li>
            <li>
              <b>12.20 - 13.20 pm</b> Theory Class 1
            </li>
            <li>
              <b>13.30 - 14.30 pm</b> Lunch
            </li>
            <li>
              <b>15.30 - 16.00 pm</b> Prānāyāma
            </li>
            <li>
              <b>16.00 - 17.20 pm</b> Theory Class 2
            </li>
            <li>
              <b>17-20 - 17.40 pm</b> Herbal tea and Fruit
            </li>
            <li>
              <b>17.40 - 19.00 pm</b> Restorative Yoga practice
            </li>
            <li>
              <b>19.30 - 20.30 pm</b> Vegetarian Dinner
            </li>
            <li>
              <b>21.00 - 21.30 pm</b> Satsanga (2 x week)
            </li>
          </ul>
        </div>
        <br></br>

        <div>
          <h3>What's included</h3>
          <ul>
            <li>Textbooks and printed course materials</li>
            <li>
              <a href="/welcomekit">Welcome BAG</a>
            </li>
            <li>Neti and Jihvadhaut Lessons</li>
            <li>All Yoga practices</li>
            <li>All workshops</li>
            <li>All theory lessons</li>
            <li>Use of yoga props during the course</li>
            <li>
              <a href="/food">Vegetarian meals</a> (3 times a day)
            </li>
            <li>
              Comfortable <a href="/house">accommodation</a> in private or
              shared rooms
            </li>
          </ul>
        </div>

        <div>
          <h3>Extra Charges</h3>
          <ul>
            <li>Airplane fees</li>
            <li>Taxi pick up from Lisboa Portela Airport 45€</li>
            <li>Taxi pick up from Lisboa Center or Train Station 55€</li>
          </ul>
        </div>

        <br></br>
      </Container>

      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default ImmersionPage;
