import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
function Slots(props) {
  return (
    <div>
      <Container>
        <Row className="d-flex flex-column justify-content-around flex-lg-row align-items-center  ">
          <Col>
            <img src={props.image} className="img-fluid " alt="yoga" />
          </Col>
          <Col>
            <h1>{props.tittle}</h1>
            <p style={{ textAlign: "left" }}>{props.text}</p>
            <a href={props.link} style={{ color: "black" }}>
              <Button variant="outline-dark" className="mt-3">
                {props.button}
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
Slots.defaultProps = {
  button: "Read More",
};
export default Slots;
