import React from "react";
import { Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import AnyQuestions from "./AnyQuestions";
import MainFooter from "./MainFooter";
import MenuBar from "./MenuBar";
import food1 from "./assets/1.1weblalitahouse.jpg";
function YogaPage() {
  return (
    <div>
      <MenuBar />
      <br />
      <br />
      <Container fluid className="p-0">
        <Carousel controls={false} indicators={false}>
          <Carousel.Item>
            <img className="d-block w-100" src={food1} alt="First slide" />
          </Carousel.Item>
        </Carousel>
      </Container>
      <p style={{ textAlign: "justify" }} className="m-5 mt-3">
        The Yoga Classes at the Lalitā House Yoga Camp are mostly based on the
        studying and execution of traditional Mantras (sacred sounds or words),
        Ṣaṭkarma (purification techniques), Prāṇāyāma (breathing exercises),
        practice of Asanās (postures), Surya Namaskār (Sun Salutation), and
        Yoganidrā (conscious relaxation). Always accompanied by the learning of
        practical steps and spiritual benefits of Dhyana (meditation),
        explanation of classic Yoga philosophy, and the reading of ancient
        Manuals.{" "}
      </p>{" "}
      <p style={{ textAlign: "justify" }} className="m-5">
        We provide textbooks and the necessary practice’s props for all the
        participants and visitors. The students of our Courses and Trainings
        will receive in addition a <a href="/welcomekit">Welcome Bag</a> with
        useful Yogic goodies.
      </p>
      <p style={{ textAlign: "justify" }} className="m-5">
        The meditation and Yoga classes are made both outdoor in the Garden and
        in the <a href="/house">Yoga Shala</a>, depending on weather conditions
        and group needs. The Courses will be taught by{" "}
        <a href="/about">qualified teachers</a> with experience in Yoga and its
        many branches.{" "}
      </p>
      <div className="d-flex flex-column flex-lg-row justify-content-evenly align-items-center">
        <Button
          variant="dark"
          className="mb-5"
          style={{ backgroundColor: "#444945" }}
        >
          <a href="/yoga/traditionalretreat" style={{ color: "white" }}>
            Traditional Retreat
          </a>
        </Button>
        <Button
          variant="dark"
          className="mb-5"
          style={{ backgroundColor: "#444945" }}
        >
          <a href="/yoga/meditationcourse" style={{ color: "white" }}>
            Meditation Course
          </a>
        </Button>
        <Button
          variant="dark"
          className="mb-5"
          style={{ backgroundColor: "#444945" }}
        >
          <a href="/yoga/immersiontraining" style={{ color: "white" }}>
            Immersion Training
          </a>
        </Button>
      </div>
      <div
        style={{ textAlign: "left" }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <h3>
          <b>Available Dates</b>
        </h3>
        <ul>
            
        <h5>
            <b>April 20th-27th (Fully Booked)</b> Traditional Yoga retreat 
          </h5>  
          <h5>
            <b>July 13rd-20th</b> Traditional Yoga retreat 
          </h5>  
          <h5>
            <b>September 14th-21st (Fully Booked)</b> Traditional Yoga retreat 
          </h5>
        </ul>
        <br></br>
        <Button
          variant="dark"
          className="mb-5"
          style={{ backgroundColor: "#444945" }}
        >
          {" "}
          <a href="/book" style={{ color: "white" }}>
            BOOK NOW
          </a>
        </Button>
      </div>
      <br></br>
      <AnyQuestions />
      <MainFooter />
    </div>
  );
}

export default YogaPage;
